import React from "react";
import { SdfPromoBox, SdfLink, SdfIcon, SdfTooltip, SdfIconButton } from "@synerg/react-components";
import { LoadHtmlFromString } from "./LoadHtmlFromString";
import {useSelector} from "react-redux";
import { TableOfContents } from "./TableOfContents";
import { ShouldLoginBox } from "../ShouldLoginBox";
import useMediaQuery from "../../hooks/useMediaQuery";
import { copyToClipboard } from "../../utility/copyToClipboard";
import './relatedGuides.scss';

export function GuideContent({ content }) {

	  const isLoggedIn = useSelector((state) => {
		return state.auth.isLoggedIn;
     });

  const isSM = useMediaQuery('(max-width: 600px)')
	const PDF =  (content?.isPublic || isLoggedIn) && content?.pdfLink &&
	<SdfLink className="cursor-pointer no-underline text-action-primary" emphasis="primary"
			external={true}
			href={content.pdfLink} target="_blank">
			Download Guide
		</SdfLink>;



	return (
		<>
			<div className="md:mx-2">
				<>{
					<div className="flex">
						<div className='w-full overflow-hidden'>
							<div className="flex mt-1">
								<section id="guideContent" className="pb-3 w-full">
									<div id="guideTitle" className="text-3xl font-bold my-4">{content.title}</div>
									<SdfPromoBox
										size='sm'
										spacing="default"
										layout="horizontal"
										align='left'
										variant='filled'
										header='Summary'
										message={content.abstract}
										illustration-name='documents'
									/>
									{
										isSM && <div className="mt-6">{PDF}</div>
									}
									{!content?.isPublic && !isLoggedIn &&
                            <div className="flex pt-4 flex-col justify-center items-center">
                                <ShouldLoginBox />
                            </div>
                  }
                  <div className="content-wrap">
									<div id="doc-preview">
										{content?.chaptersSections.map((chapter, index) => {
												return (<div key={index}>
											   <div className={`text-tertiary font-medium mt-8 simple h2-${index+1}`} chapter-id={`${index + 1}`}>
														CHAPTER {index + 1}  
													</div>
													<div className="flex items-center">
													 <h2> {chapter.title}</h2>
													 <SdfTooltip tooltipId="action-link" attachmentPoint="right" trigger="click" className="mt-1 ml-2">
                            <SdfIconButton slot="tooltip-target"  onClick = {()=> copyToClipboard(`${window.location.href}/chapter/${index + 1}`)}>
                                <SdfIcon icon="action-link"  className="text-xl cursor-pointer" ></SdfIcon>
                            </SdfIconButton>
                            <span>
														Copied link
                            </span>
                        </SdfTooltip>
												</div>
												<LoadHtmlFromString
													key={index}
													htmlStr={chapter.content}
													className="ml-6"
												/>
												</div>)
										})}
									</div>
									</div>
									<footer id="guideContentFooter"></footer>
								</section>
							</div>
						</div>
						<div className="flex flex-col">
							<div className="flex flex-col sticky" style={{ top: '5.50rem' }}>
								<aside className="md:mx-6">
								{!isSM && 
                     PDF
								}
									<TableOfContents
										currentGuideSections={content?.chaptersSections}
									/>
								</aside>
							</div>

						</div>
					</div>
				}</>
			</div>
		</>
	)

}

