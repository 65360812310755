import React, { useEffect } from "react";
import DOMPurify from "dompurify";
import './relatedGuides.scss';
import { findElements } from "../../utility/DOMUtils";

const makeTableScrollable = (tableElement) => {
    if (!tableElement.parentElement.classList.contains('table-wrapper')) {
        const wrapper = document.createElement('div');
        wrapper.classList.add('table-wrapper');
        wrapper.style.overflowX = 'auto';
        wrapper.width = "100%"
        tableElement.parentNode.insertBefore(wrapper, tableElement);
        wrapper.appendChild(tableElement);
    }

}

const resetOptions = {
    'TABLE': (table) => {
        table.removeAttribute('border')
        table.cellSpacing = 0
        table.cellPadding = 0
        const hasTHead = !!table.querySelector('thead')
        table.classList.add(hasTHead ? 'table-with-thead' : 'table-without-thead')
        makeTableScrollable(table)
    },
    'PRE': (pre) => makeTableScrollable(pre)
}

export function LoadHtmlFromString({ htmlStr, className = '' }) {
    const resetInlineStyle = (elements, elementsToReset) => {
        elements.forEach(element => {
            const docPreviewElements = element.querySelectorAll(elementsToReset)
            docPreviewElements.forEach(docPreviewElement => {
                docPreviewElement.removeAttribute('style')
                resetOptions[docPreviewElement.tagName]?.(docPreviewElement)
            })

        })
    }

    useEffect(() => {
        const elements = findElements('#doc-preview')
        elements.length > 0 && resetInlineStyle(elements, 'table,th,td,p,pre,code')
    }, [htmlStr])

    return (
        <div className={`dev-portal-custom-style ${className}`}>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlStr) }} />
        </div>
    )
}